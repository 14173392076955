body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App{
  /* height: 100%; */
  /* min-height: 100%; */
  color: rgba(0,0,0,0.85);;
}

.ant-tooltip-inner{
  background: #fff !important;
  color: #000 !important;
  font-size: 12px;
  width: 103px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
  border-radius: 4px;
}

.ant-tooltip-arrow::before{
  background: #fff !important;
}

/* 通用 scroll 样式 */
.commonScrollStyle::-webkit-scrollbar{
  width:10px;
  height:10px;
}

.commonScrollStyle::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.ant-btn-primary{
  background: linear-gradient(to bottom, #73A6FE, #5B57EA) !important;
  border: none !important;
}

.ant-modal-wrap{
  background: rgba(1,0,45,0.53);
}

.ant-form-item-required::before{
  font-size: 16px !important;
}

/* form .ant-select{
  width: unset !important;
} */
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: linear-gradient(to bottom, #73A6FE, #5B57EA) !important;
  border: 1px solid transparent !important;
}

.ant-switch-checked{
  background: #6A71DF !important;
}